<template>
    <div class="k-flex-col k-gap-m">
        <div class="k-flex-row-2 k-flex-fill">
            <b-form-group>
                <label>
                    <b-icon-box-arrow-right /> Projet source
                    <span class="text-danger">*</span>
                </label>
                <b-form-select
                    v-model="selectedSrcProjectId"
                    :options="projects"
                    value-field="id"
                    text-field="name"
                >
                    <template #first>
                        <b-select-option :value="null" disabled>
                            -- Choisir un projet --
                        </b-select-option>
                    </template>
                </b-form-select>
                <b-form-invalid-feedback :state="isSrcProjectValid">
                    Veuillez sélectionner un projet source
                </b-form-invalid-feedback>
            </b-form-group>
        </div>
        <div class="k-flex-center mt-2">
            <b-button variant="kalkin-2" @click="duplicate">
                Dupliquer le projet
            </b-button>
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            selectedSrcProjectId: null,
            projects: [],
            validated: false,
        };
    },

    watch: {
        selectedSrcProjectId(newValue, oldValue) {
            if (this.selectedDestProjectId === oldValue) {
                this.selectedDestProjectId = newValue;
            }
        },
    },

    computed: {
        isSrcProjectValid() {
            return (
                !!this.selectedSrcProjectId || (this.validated ? false : null)
            );
        },

        isValid() {
            return this.isSrcProjectValid;
        },
    },

    methods: {
        async fetchProjects() {
            this.projects = await this.$store.dispatch(
                "project/getFSPProjects",
                {
                    sortby: "name",
                },
            );
        },

        async duplicate() {
            this.validated = true;
            if (this.isValid) {
                await this.$store.dispatch("admin/cloneProject", {
                    config: {
                        from: {
                            project: this.selectedSrcProjectId,
                        },
                    },
                });
            }
        },
    },

    async beforeMount() {
        await this.fetchProjects();
    },
};
</script>
