<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>Outils de duplication</h1>
        </div>

        <b-tabs content-class="mt-3 px-3">
            <b-tab title="Duplication d'arborescence" active>
                <PanelCloneArbo />
            </b-tab>
            <b-tab title="Duplication de projet" active>
                <PanelCloneProject />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import PanelCloneArbo from "@/components/misc/admin-cloning-tool/PanelCloneArbo";
import PanelCloneProject from "@/components/misc/admin-cloning-tool/PanelCloneProject";

export default {
    components: {
        PanelCloneArbo,
        PanelCloneProject,
    },
};
</script>
