<template>
    <div class="k-flex-col k-gap-m">
        <div class="p-3 text-kalkin-1">
            <b-icon-info-circle /> Par défaut, la duplication d'arborescence va
            faire une copie de l'arborescence et ses réglages, de tous les
            menus, leurs options et leurs associations avec les sélections de
            données. D'autres données peuvent être dupliquées de manière
            optionnelle.
        </div>

        <div class="k-flex-row-2 k-flex-fill">
            <b-form-group>
                <label>
                    <b-icon-box-arrow-right /> Projet source
                    <span class="text-danger">*</span>
                </label>
                <b-form-select
                    v-model="selectedSrcProjectId"
                    :options="projects"
                    value-field="id"
                    text-field="name"
                    @change="fetchArbos"
                >
                    <template #first>
                        <b-select-option :value="null" disabled>
                            -- Choisir un projet --
                        </b-select-option>
                    </template>
                </b-form-select>
                <b-form-invalid-feedback :state="isSrcProjectValid">
                    Veuillez sélectionner un projet source
                </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group>
                <label>
                    Arborescence à dupliquer
                    <span class="text-danger">*</span>
                </label>
                <b-select
                    v-model="selectedSrcArboId"
                    :options="arbos"
                    value-field="id"
                    text-field="name"
                    :disabled="!selectedSrcProjectId"
                    @change="fetchSrcConfigs"
                >
                    <template #first>
                        <b-select-option :value="null" disabled>
                            -- Choisir une arborescence --
                        </b-select-option>
                    </template>
                </b-select>
                <b-form-invalid-feedback :state="isSrcArboValid">
                    Veuillez sélectionner une arborescence
                </b-form-invalid-feedback>
            </b-form-group>
        </div>

        <div class="k-flex-row-2 k-flex-fill">
            <b-form-group>
                <label>
                    <b-icon-box-arrow-in-right /> Projet de destination
                    <span class="text-danger">*</span>
                </label>
                <b-select
                    v-model="selectedDestProjectId"
                    :options="projects"
                    value-field="id"
                    text-field="name"
                    @input="fetchDestConfigs"
                >
                    <template #first>
                        <b-select-option :value="null" disabled>
                            -- Choisir un projet --
                        </b-select-option>
                    </template>
                </b-select>
                <b-form-invalid-feedback :state="isDestProjectValid">
                    Veuillez sélectionner un projet de destination
                </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group>
                <label>
                    Nom de la nouvelle arborescence
                    <span class="text-danger">*</span>
                </label>
                <b-input type="text" v-model="arboName"></b-input>
                <b-form-invalid-feedback :state="isArboNameValid">
                    Veuillez donner un nom à la nouvelle arborescence
                </b-form-invalid-feedback>
            </b-form-group>
        </div>

        <div class="k-flex-row-2 mt-2 flex-nowrap" v-if="areSameProject">
            <b-checkbox v-model="cloneData" />
            <div>
                Dupliquer les sources de données et leurs sélections de données
                <small>
                    (sinon elles sont partagées)
                </small>
            </div>
        </div>
        <div class="k-flex-row-2 mt-2 flex-nowrap" v-else>
            <b-checkbox :checked="true" disabled />
            <div>
                Dupliquer les sources de données et les sélections de données
                <small class="text-danger">
                    (Obligatoire car projets différents)
                </small>
            </div>
        </div>

        <div class="k-flex-row-2 flex-nowrap">
            <b-checkbox v-model="cloneI18n" />Dupliquer les traductions
        </div>

        <div class="k-flex-row-2 flex-nowrap">
            <b-checkbox v-model="cloneStyles" />
            <div>
                Dupliquer les associations aux configurations et leurs
                <b>styles de menus</b>
            </div>
        </div>

        <div class="k-flex-col px-3" v-show="cloneStyles">
            <div
                class="k-flex-row-2 k-flex-fill"
                v-for="config in srcConfigs"
                :key="config.id"
            >
                <div>
                    <label>Configuration source</label>
                    <div class="border rounded p-1">{{ config.name }}</div>
                </div>

                <div>
                    <label>
                        Configuration de destination
                        <span class="text-danger">*</span>
                    </label>
                    <b-select
                        v-model="selectedDestConfigs[config.id]"
                        :options="destConfigs"
                        value-field="id"
                        text-field="name"
                    >
                        <template #first>
                            <b-select-option :value="undefined" disabled>
                                -- Choisir une configuration --
                            </b-select-option>
                        </template>
                    </b-select>
                </div>
            </div>
        </div>

        <b-form-invalid-feedback :state="areDestConfigsValid">
            Veuillez selectionner la ou les configurations de destination
        </b-form-invalid-feedback>

        <div class="k-flex-center mt-2">
            <b-button variant="kalkin-2" @click="duplicate">
                Dupliquer l'arborescence
            </b-button>
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            selectedSrcProjectId: null,
            selectedSrcArboId: null,
            selectedDestProjectId: null,
            arboName: "Nouvelle copie",

            projects: [],
            arbos: [],

            cloneData: false,
            cloneI18n: false,
            cloneStyles: false,

            srcConfigs: [],
            destConfigs: [],

            selectedDestConfigs: {},

            validated: false,
        };
    },

    watch: {
        selectedSrcProjectId(newValue, oldValue) {
            if (this.selectedDestProjectId === oldValue) {
                this.selectedDestProjectId = newValue;
            }
        },
    },

    computed: {
        areSameProject() {
            return this.selectedSrcProjectId === this.selectedDestProjectId;
        },

        forcedCloneData() {
            return !this.areSameProject || this.cloneData;
        },

        isSrcProjectValid() {
            return (
                !!this.selectedSrcProjectId || (this.validated ? false : null)
            );
        },

        isSrcArboValid() {
            return !!this.selectedSrcArboId || (this.validated ? false : null);
        },

        isDestProjectValid() {
            return (
                !!this.selectedDestProjectId || (this.validated ? false : null)
            );
        },

        isArboNameValid() {
            if (this.arboName.length > 0) {
                return true;
            } else {
                return this.validated ? false : null;
            }
        },

        areDestConfigsValid() {
            if (
                this.cloneStyles &&
                this.srcConfigs.some(c => !this.selectedDestConfigs[c.id])
            ) {
                return this.validated ? false : null;
            } else {
                return true;
            }
        },

        isValid() {
            return (
                this.isSrcProjectValid &&
                this.isSrcArboValid &&
                this.isDestProjectValid &&
                this.isArboNameValid &&
                this.areDestConfigsValid
            );
        },
    },

    methods: {
        async fetchProjects() {
            this.projects = await this.$store.dispatch(
                "project/getFSPProjects",
                {
                    sortby: "name",
                },
            );
        },

        async fetchArbos() {
            this.arbos = await this.$store.dispatch("project/getArbos", {
                projectId: this.selectedSrcProjectId,
            });
            this.selectedSrcArboId = null;
        },

        async fetchSrcConfigs() {
            this.srcConfigs = (
                await this.$store.dispatch("arbo/getApplicationConfigs", {
                    projectId: this.selectedSrcProjectId,
                    arboId: this.selectedSrcArboId,
                })
            ).map(c => c.ApplicationConfig);
        },

        async fetchDestConfigs() {
            this.destConfigs = await this.$store.dispatch(
                "application/getApplicationConfigs",
                {
                    projectId: this.selectedDestProjectId,
                },
            );
            this.selectedDestConfigs = {};
        },

        async duplicate() {
            this.validated = true;
            if (this.isValid) {
                await this.$store.dispatch("admin/cloneArbo", {
                    config: {
                        from: {
                            project: this.selectedSrcProjectId,
                            id: this.selectedSrcArboId,
                        },
                        to: {
                            project: this.selectedDestProjectId,
                            name: this.arboName,
                        },
                        options: {
                            data: { enable: this.forcedCloneData },
                            i18n: { enable: this.cloneI18n },
                            styles: {
                                enable: this.cloneStyles,
                                config: Object.entries(
                                    this.selectedDestConfigs,
                                ).map(([k, v]) => ({ from: k, to: v })),
                            },
                        },
                    },
                });
            }
        },
    },

    async beforeMount() {
        await this.fetchProjects();
    },
};
</script>
